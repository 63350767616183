import React from 'react';
import PageLayout from '../components/PageLayout';
import styled, { ThemeProvider } from 'styled-components';
import Button from '../components/Button';
import { Input, TextArea } from '../components/InputComponents';
import { SuccessMsg } from '../components/Message';

const successMessage = `Thanks for contacting us. We'll get back to you as soon as possible.`;

function Contact({className}) {
  const isSuccess = () => {
    if(typeof window === 'undefined') { // Gatsby Gotcha: We don't have 'window' in SSR stage
      return false;
    }
    return window.location.hash.indexOf('success') !== -1;
  }
  return (
    <PageLayout title="Contact" uppercaseTitle titleAnim>
      <div className={className}>
        {isSuccess() && <SuccessMsg>{successMessage}</SuccessMsg>}
        <div className="main-body">
          <div className="text-area">
            <p>We'd love to have your engagement and feedback to feature our future projects, and help emerging artists in the community.</p>
            <p>
              Message can either be sent in <b>English</b> or <b>Chinese</b>.
            </p>
          </div>
          <form 
            name="contact"
            method="post" 
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="/contact#success"
          >
            <input type="hidden" name="form-name" value="contact" />
            <Input name="name" aria-label="name" placeholder="Your name" required/>
            <Input name="email" aria-label="email" type="email" placeholder="Your email" required/>
            <TextArea name='message' area-label="message" placeholder="Your message" maxlength="500"/>
            <ThemeProvider theme={{style: "default"}}>
              <Button type='submit'>SEND</Button>
            </ThemeProvider>
          </form>
        </div>
      </div>
    </PageLayout>
  )
}

export default styled(Contact)`
input {
  margin-bottom: 16px;
}
.main-body {
  .text-area {
    margin-right: 16px;
    max-width: 50%;
  }

  display: flex;

  form {
    flex-grow: 1;
  }
}

width: 66.6%;

@media screen and (max-width: 1024px) {
  .main-body {
    flex-direction: column;
    .text-area {
      margin-right: 0;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 414px) {
  width: 100%;
}

`;
